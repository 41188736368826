
export default {
  name: "Picture",
  props: {
    image: {
      type: Object,
      default() {},
    },
    isLazyLoading: {
      type: Boolean,
      default: true
    }
  },
};
