import site_settings_locales from '../locales.json'
import all_site_settings from '../site-settings.json'

export const state = () => ({
  defaultLocation: 'U.A.E.',
  defaultLang: 'en-AE',
  locales: [],
  localedData: {},
  localesLocations: [],
  selectedLocation: {},
  selectedLanguage: {},
})

export const getters = {
  getDefaultLocation(state) {
    return state.defaultLocation
  },
  getDefaultLang(state) {
    return state.defaultLang
  },

  getLocales(state) {
    return state.locales
  },

  getLocalesLocations(state) {
    return state.localesLocations
  },

  getSelectedLocation(state) {
    return state.selectedLocation
  },

  getSelectedLanguage(state) {
    return state.selectedLanguage
  },

  getHeaderSettings(state) {
    return state.localedData.headerSettings ?? {}
  },

  getFooterSettings(state) {
    return state.localedData.footerSettings ?? {}
  },

  getDownloadURLs(state) {
    return state.localedData.downloadURLs ?? {}
  },
  
  getLegacy(state) {
    return state.localedData.legacy ?? {}
  },

  getCookieBar(state) {
    return state.localedData.cookieBar ?? {}
  },
}

export const mutations = {
  setDefaultLang(state, defaultLang) {
    state.defaultLang = defaultLang;
  },

  setDefaultLocation(state, defaultLocation) {
    state.defaultdefaultLocation = defaultLocation;
  },

  setLocales(state, locales) {
    state.locales = locales;
  },

  setLocaledData(state, localedData) {
    state.localedData = localedData;
  },

  setSelectedLocation(state, selectedLocation) {
    state.selectedLocation = selectedLocation;
  },

  setSelectedLanguage(state, selectedLanguage) {
    state.selectedLanguage = selectedLanguage;
  },

  setLocalesLocations(state, localesLocations) {
    state.localesLocations = localesLocations;
  }
}

export const actions = {
  async fetchSiteSetting({
    commit,
    state
  }) {
    commit('setLocales', site_settings_locales)
  },

  async fetchLocalesLocations({
    commit,
    state
  }) {
    let allLocalesLocations = []
    for (let locale of site_settings_locales) {
      await allLocalesLocations.push({
        id: locale.id,
        title: locale.location,
        availableLanguages: locale.availableLanguages.map((language) => ({
          id: language.id,
          title: language.language,
          iso: language.iso
        }))
      })
    }
    commit('setLocalesLocations', allLocalesLocations)
  },

  async fetchLocaledData({
    commit,
    state
  }, lang) {
    try {
      for (let settings of all_site_settings?.data) {
        if (settings.locale == lang) {
          commit('setLocaledData', settings)
        } else if (settings.locale == state.defaultLang) {
          commit('setLocaledData', settings)
        }
      }
    } catch {

    }
  }
}
