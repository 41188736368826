import languages from "@/languages.json";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      path: this.$utils.helper.getPathWithOutLang(this.$route.path),
      activeLang: this.$utils.helper.getLangFromPath(this.$route.path),
      browserLang: '',
      dirLang: false
      // Site Settings Data Params
    }
  },
  computed: {
    ...mapGetters({
      default_lang: 'getDefaultLang',
      locales: 'getLocales',
      header: 'getHeaderSettings',
      footer: 'getFooterSettings',
      download: 'getDownloadURLs',
      legacy: 'getLegacy',
      cookie: 'getCookieBar',
    }),

    notification() {
      return this.header.notification ? this.header.notification : {}
    },
    smartBanner() {
      return this.header.smartBanner ? this.header.smartBanner : {}
    },
  },
  async beforeCreate() {
    await this.activeLang

    this.dirLang = this.activeLang?.slice(0, 2) === "ar";

  },
  beforeMount() {
    languages.filter((item) => {
      this.browserLang = item.iso === navigator.language ? item.iso : ''
    })
  },
  methods: {
  }
}