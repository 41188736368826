const redirects = [
  {
    regex: /^\/trackride\/([a-zA-Z0-9]{1,11})[/]?$/, 
    to: (m)=>`https://app.careem.com/trackride/${m[1]}`
  },
  {
    regex: /^\/signup\/([a-zA-Z0-9]{1,11})[/]?$/, 
    to: (m)=>`https://app.careem.com/signup/${m[1]}`
  },
  {
    regex: /^\/invite\/([a-zA-Z0-9]{1,11})[/]?$/, 
    to: (m)=>`https://app.careem.com/signup/${m[1]}`
  },
  {
    regex: /^\/reset\/([a-zA-Z0-9]{1,11})[/]?$/, 
    to: (m)=>`https://app.careem.com/reset/${m[1]}`
  },
]

const quickRedirect = {
  '/':'/en-AE/',
  '/corporate':'http://corporate.careem-engineering.com/corporate/',
  '/signup':'https://app.careem.com/signup',
  '/drive':'https://drive.careem.com/?utm_source=web&utm_medium=careem.com',
  '/app':'https://app.careem.com/app',
  '/faqs':'https://help.careem.com/hc/en-us',
  '/help':'https://help.careem.com/hc/en-us',
  '/en-ae/faqs':'https://help.careem.com/hc/en-us',
  '/security':'/en-AE/security',
  '/careem-now':'/en-AE/food',
  '/food':'/en-AE/food',
  '/careem-ride':'/en-AE/ride',
  '/fare-estimator':'/en-AE/ride',
  '/ride':'/en-AE/ride',
  '/careemplus':'/en-AE/cplus',
  '/cplus':'/en-AE/cplus',
  '/careem-pay':'/en-AE/pay',
  '/pay':'/en-AE/pay',
  '/about-careem':'/en-AE/about-us',
  '/our-story':'/en-AE/about-us',
  '/about-us':'/en-AE/about-us',
  '/careers':'/en-AE/life-at-careem',
  '/life-at-careem':'/en-AE/life-at-careem',
  '/terms':'/en-AE/terms-of-service',
  '/terms-of-service':'/en-AE/terms-of-service',
  '/privacy':'/en-AE/privacy-policy',
  '/privacy-policy':'/en-AE/privacy-policy',
  '/cleaning':'/en-AE/cleaning',
  '/our-social-impact':'/en-AE/our-social-impact',
  '/captains':'/en-AE/captains',
  '/quik-grocery':'/en-AE/quik-grocery',
  '/bill-payments':'/en-AE/bill-payments',
  '/pay-bill':'/en-AE/bill-payments',
  '/peer-to-peer':'/en-AE/peer-to-peer',
  '/send-money':'/en-AE/peer-to-peer',
  '/delivery':'/en-AE/delivery',
  '/engineering-at-careem':'/en-AE/engineering-at-careem',
}

export default function(req, res, next) {
    let redirect = quickRedirect[req.url];

    if(!redirect) {
      redirect = redirects.find((r) => Boolean(req.url?.match(r.regex)))
    }else{
      redirect={ to: redirect}
    }
    
    // no redirecting
    if (!redirect) return next();

    //redirecting
    const matches= req.url?.match(redirect.regex)
    res.writeHead(301, { Location: (typeof redirect.to) == 'function' ? redirect.to(matches) :  redirect.to })
    res.end();
  }