
export default {
  props: {
    id: {
      type: String,
      default: "text",
    },
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "name",
    },
    inputmode: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
