import site_settings_locales from '../locales.json';

let availableLocales = [];

for (let locale of site_settings_locales) {
  const isos = locale.availableLanguages.map(l => l.iso);
  availableLocales = [...availableLocales, ...isos];
}

export default _ => ({
  getLangFromPath(path) {

    if (path.toLowerCase() === '/iframe.html') return;

    const arr = path.split('/').filter(x => x.length > 0);
    const lang = arr.length > 0 ? arr[0].split('-') : [];

    // for en-AE :: lang = [ en , AE ]

    const langISO = `${lang[0]?.toLowerCase()}-${lang[1]?.toUpperCase()}`;

    if (availableLocales.includes(langISO)) return langISO;
    return this.getDefaultLocale();
  },

  getDefaultLocale() {
    return 'en-AE';
  },

  getPathWithOutLang(path) {
    const lang = this.getLangFromPath(path);
    const filteredItems = path.split('/').filter(item => item.length > 0 && item?.toLowerCase() !== lang?.toLowerCase());
    return `/${filteredItems.join('/')?.toLowerCase()}`;
  }
})
